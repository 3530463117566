.form__button-submit {
  cursor: pointer;
  text-align: center;
  width: 100%;
  height: 50px;
  margin-top: 18px;
  padding: 0;
  outline: none;
  border: none;
  border-radius: 2px;
  background-color: #000;
  font-family: "Inter", Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  color: #fff;
  transition: opacity .3s linear;
}
.form__button-submit:hover {
  opacity: .8;
}
.form__button-submit:disabled {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  opacity: .2;
  pointer-events: none;
  user-select: none;
}

@media screen and (max-width: 767px) {
  .form__button-submit {
    height: 46px;
    margin-top: 45px;
    font-size: 14px;
 }
}