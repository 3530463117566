.form__input {
  display: block;
  width: 100%;
  padding: 0 0 9px 0;
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #000;
}
