.footer {
  width: 68.75%;
  max-width: 880px;
  margin: 67px auto 0 auto;
  padding-bottom: 60px;
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .footer {
    width: 80%;
}
}
@media screen and (max-width: 767px) {
  .footer {
    width: 88.125%;
    margin: 48px auto 0 auto;
    padding-bottom: 36px;
 }
}