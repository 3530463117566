.header__email {
  font-family: 'Inter', Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #fff;
  margin: 0 24px 0 0;
}

@media screen and (max-width: 767px) {
  .header__email {
    margin: 0 0 18px;
 }
}