.popup__button-close {
  background: transparent url(../../../images/popup-button-close.svg) center / cover no-repeat;
  width: 32px;
  height: 32px;
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: -37px;
  right: -37px;
  transition: opacity .3s linear;
}
.popup__button-close:hover {
  opacity: .6;
}
@media screen and (max-width: 767px) {
  .popup__button-close {
    width: 20px;
    height: 20px;
    right: 0;
 }
}