.header__nav {
  display: flex;
  visibility: visible;
}

@media screen and (max-width: 767px) {
  .header__nav {
    margin: 0;
    visibility: hidden;
    height: 0;
 }
}