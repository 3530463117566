.header__logo {
  width: 142px;
 
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .header__logo {
    width: 120px;
 }
}
@media screen and (max-width: 767px) {
  .header__logo {
    width: 103.74px;
    margin: 0 0 0 27px;
 }
}