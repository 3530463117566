.auth__link {
  font-family: "Inter", Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.21;
  color: #fff;
  text-align: center;
  margin: 15px 0;
  transition: opacity .3s linear;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
}

.auth__link:hover {
  opacity: .6;
}

@media screen and (max-width: 767px) {
  .auth__link {
    margin: 20px 0;
 }
}