.popup__delete-title {
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.2;
  color:#000;
  margin: 0 0 18px 0;
}

@media screen and (max-width: 767px) {
  .popup__delete-title {
    font-size: 18px;
    margin: 0;
 }
}