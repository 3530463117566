.header {
  width: 68.75%;
  max-width: 880px;
  display: grid;
  margin: 0 auto;
  padding-top: 45px;
  border-bottom: 1px solid rgba(84, 84, 84, .7);
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .header {
    width: 80%;
 }
}
@media screen and (max-width: 767px) {
  .header {
    width: 100%;
    padding-top: 28px;
 }
}