.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  visibility: hidden;
  background-color: rgba(0, 0, 0, .5);
  opacity: 0;
  transition: all .3s linear;
}