.profile__about {
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.22;
  color: #FFF;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .profile__about {
    font-size: 14px;
    text-align: center;
    padding-left: 28px;
 }
}