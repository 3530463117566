.profile__edit-button {
  background: transparent url(../../../images/edit-button.svg) center no-repeat;
  width: 24px;
  height: 24px;
  align-self: center;
  border: 1px solid #fff;
  cursor: pointer;
  transition: opacity .3s linear;
}
.profile__edit-button:hover {
  opacity: .6;
}
@media screen and (max-width: 767px) {
  .profile__edit-button {
    max-width: 18px;
    height: 18px;
 }
}