.auth__title {
  font-family: 'Inter', Arial, sans-serif;
  font-size: 24px;
  font-weight: 900;
  line-height: 1.2;
  color: #fff;
  text-align: center;
  margin: 60px 0 50px;
}

@media screen and (max-width: 767px) {
  .auth__title {
    font-size: 20px;
    margin: 40px 0 40px;
  }
}