.profile__name {
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 1.14;
  color: #fff;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .profile__name {
    font-size: 27px;
    line-height: 1.22;
    text-align: center;
    padding-left: 28px;
 }
}