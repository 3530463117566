.popup__delete-container {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 25px rgb(0 0 0 / 15%);
  box-sizing: border-box;
  max-width: 430px;
  min-width: 430px;
  padding: 34px 36px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .popup__delete-container {
    min-width: 282px;
    max-width: 282px;
    padding: 25px 22px;
  }
}
