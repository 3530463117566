.popup__registration-icon {
  width: 120px;
  height: 120px;
  display: flex;
  margin: 26px auto 32px;
}

@media screen and (max-width: 767px) {
  .popup__registration-icon {
    margin: 25px auto 40px;
 }
}