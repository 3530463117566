.popup__title {
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-size: 24px;
  font-weight: 900;
  line-height: 1.2;
  color: #000;
  margin: 0 0 41px;
}
@media screen and (max-width: 767px) {
  .popup__title {
    font-size: 18px;
    margin: 0 0 62px;
 }
}
