.popup__registration-title {
  font-family: 'Inter', Arial, sans-serif;
  font-size: 24px;
  font-weight: 900;
  line-height: 1.2;
  color: #000;
  text-align: center;
  display: flex;
  margin: 0 0 26px;
}

@media screen and (max-width: 767px) {
  .popup__registration-title {
    margin: 0 0 25px;
    font-size: 20px;
 }
}