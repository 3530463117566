.header__container {
    margin: 0 0 41px;
    display: grid;
    width: 100%;
    grid-template-columns: auto auto auto;
    justify-content: space-between;
    align-items: center;
    
}

@media screen and (max-width: 767px) {
    .header__container {
      margin: 0 0 31.6px;
      grid-template-columns: auto auto;
   }
  }